import { WorkflowType } from "@/constants/CommonConstats";
import { t } from "@/translations";

export const BASE_PATH = "context-steps";

export const createBasePath = index => BASE_PATH + "[" + index + "]";

export const PERMISSION_ASSIGNMENT_APPROVAL = "PERMISSION_ASSIGNMENT_APPROVAL";

export const DELETE_CLUSTER_APPROVAL = "DELETE_CLUSTER_APPROVAL";

export const DELETE_INTEGRATION_MANAGER_INSTANCE_APPROVAL =
    "DELETE_INTEGRATION_MANAGER_INSTANCE_APPROVAL";

export const DELETE_PARTITION_APPROVAL = "DELETE_PARTITION_APPROVAL";

export const CALCULATION_ENGINE_APPROVAL = "CALCULATION_ENGINE_APPROVAL";
export const PARTITION_PROVISIONING_APPROVAL =
    "PARTITION_PROVISIONING_APPROVAL";

export const PFX_ENG_SUPPORT_USER_APPROVAL = "PFX_ENG_SUPPORT_USER_APPROVAL";

export const EVENT_ORCHESTRATION_APPROVAL = "EVENT_ORCHESTRATION_APPROVAL";

// TODO: this should go from some api
export const WF_TYPES = [
    {
        label: t("workflow-type.CREATE_PARTITION_APPROVAL"),
        value: "CREATE_PARTITION_APPROVAL",
        isGlobalWorkflowOnly: false,
    },
    {
        label: t("workflow-type.DELETE_CLUSTER_APPROVAL"),
        value: DELETE_CLUSTER_APPROVAL,
        isGlobalWorkflowOnly: true,
    },
    {
        label: t("workflow-type.PERMISSION_ASSIGNMENT_APPROVAL"),
        value: PERMISSION_ASSIGNMENT_APPROVAL,
        isGlobalWorkflowOnly: false,
    },
    {
        label: t("workflow-type.DELETE_INTEGRATION_MANAGER_INSTANCE_APPROVAL"),
        value: DELETE_INTEGRATION_MANAGER_INSTANCE_APPROVAL,
        isGlobalWorkflowOnly: true,
    },
    {
        label: t("workflow-type.PARTITION_PROVISIONING_APPROVAL"),
        value: PARTITION_PROVISIONING_APPROVAL,
        isGlobalWorkflowOnly: false,
    },
    {
        label: t("workflow-type.DELETE_PARTITION_APPROVAL"),
        value: DELETE_PARTITION_APPROVAL,
        isGlobalWorkflowOnly: false,
    },
    {
        label: t("workflow-type.CALCULATION_ENGINE_APPROVAL"),
        value: CALCULATION_ENGINE_APPROVAL,
        isGlobalWorkflowOnly: false,
    },
    {
        label: t("workflow-type.PFX_ENG_SUPPORT_USER_APPROVAL"),
        value: PFX_ENG_SUPPORT_USER_APPROVAL,
        isGlobalWorkflowOnly: false,
    },
    {
        label: WorkflowType.translate(WorkflowType.SAML_SSO_APPROVAL),
        value: WorkflowType.SAML_SSO_APPROVAL,
        isGlobalWorkflowOnly: false,
    },
    {
        label: "Event orchestration approval",
        value: EVENT_ORCHESTRATION_APPROVAL,
        isGlobalWorkflowOnly: false,
    },
];

export const WF_PERMISSION_CONDITIONS = [
    {
        label: t("workflow-permission.alerts.edit"),
        value: JSON.stringify({ permission: "alerts.edit", entityTypes: [] }),
    },
    {
        label: t("workflow-permission.partition.allow-create"),
        value: JSON.stringify({
            permission: "provisioning.partition",
            entityTypes: [],
        }),
    },
];

export const WF_PERMISSION_CONDITIONS_DEFAULT_WF = [
    {
        label: t("workflow-permission.partition.global-asset-class"),
        value: JSON.stringify({
            permission: null,
            entityTypes: ["ALL_INTEGRATIONS", "ALL_ACCOUNTS", "ALL_PARTITIONS"],
        }),
    },
];
